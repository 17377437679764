<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <c-card title="기본정보" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-show="editable && isOld && isRegDept" label="삭제" icon="delete_forever" @btnClicked="removeData" />
              <c-btn
                v-show="editable && isRegDept"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="assessData"
                :mappingType="mappingType"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                required
                :editable="editable"
                :disabled="!isRegDept"
                type="edit"
                name="plantCd"
                v-model="assessData.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                required
                type="year"
                default="today"
                :disabled="!isRegDept"
                :editable="editable"
                label="평가년도"
                name="eiaDate"
                v-model="assessData.eiaDate">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-dept
                required
                type="edit"
                :disabled="!isRegDept"
                :editable="editable"
                label="작성부서"
                name="eiaDeptCd"
                v-model="assessData.eiaDeptCd">
              </c-dept>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                required
                :disabled="!isRegDept"
                :editable="editable"
                label="제목"
                name="eiaTitle"
                v-model="assessData.eiaTitle">
              </c-text>
            </div>
          </template>
        </c-card>
        <!-- quarterBorder -->
        <div 
          v-for="(quarter, idx) in resultQuarter"
          :key="idx"
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <q-btn-group outline >
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-btn
                v-show="quarter.isApprReq && editable && isRegDept"
                :url="approveUrl"
                :isSubmit="quarter.isApproveRequest"
                :param="quarter.param"
                mappingType="PUT"
                :label="`승인요청`"
                icon="check"
                color="purple"
                @beforeAction="approveReqData(quarter)"
                @btnCallback="approveCallback" />
              <c-btn
                v-show="quarter.isApprCom && editable && isEnvSafDept"
                :url="approveUrl"
                :isSubmit="quarter.isApprove"
                :param="quarter.param"
                color="blue"
                mappingType="PUT"
                :label="`승인`"
                icon="check"
                @beforeAction="approveData(quarter)"
                @btnCallback="approveCallback" />
              <c-btn
                v-show="quarter.isApprCom && editable && isEnvSafDept"
                :url="companionUrl"
                color="red"
                :isSubmit="quarter.isCompanion"
                :param="quarter.param"
                mappingType="PUT"
                :label="`반려`"
                icon="refresh"
                @beforeAction="companionData(quarter)"
                @btnCallback="approveCallback" />
              <q-chip
                v-show="isOld && editable"
                :color="setTagColor(quarter)"
                outline square
                class="inspectionSubjectFlag-blinking"
                text-color="white"
                icon="alarm">
                {{setTagName(quarter)}}
              </q-chip>
            </div>
          </q-btn-group>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-upload 
              :attachInfo="quarter.attachInfo"
              style="padding-top:15px!important;"
              :label="`${quarter.name} 평가결과 업로드`"
              :editable="editable && isRegDept">
            </c-upload>
          </div>
          <div v-if="quarter.statusCd === 'EQS0000010' || quarter.statusCd === 'EQS0000001'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:20px!important">
            <c-text
              :disabled="!isEnvSafDept || quarter.statusCd === 'EQS0000010'"
              :editable="editable"
              label="반려사유"
              name="companionContent"
              v-model="quarter.companionContent">
            </c-text>
          </div>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'env-impact-assessment-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eiaAssessmentId: '',
      }),
    },
  },
  data() {
    return {
      fstAttachInfo: {
        isSubmit: '',
        taskClassCd: 'EIA_FST_QUARTER',
        taskKey: '',
      },
      secAttachInfo: {
        isSubmit: '',
        taskClassCd: 'EIA_SEC_QUARTER',
        taskKey: '',
      },
      trdAttachInfo: {
        isSubmit: '',
        taskClassCd: 'EIA_TRD_QUARTER',
        taskKey: '',
      },
      frtAttachInfo: {
        isSubmit: '',
        taskClassCd: 'EIA_FRT_QUARTER',
        taskKey: '',
      },
      saveUrl: '',
      mappingType: 'POST',
      regUserName: '',
      deleteUrl: '',
      editable: true,
      isSave: false,
      isFirstApprove: false,
      isSecondApprove: false,
      isThirdApprove: false,
      isFourthApprove: false,
      isFirstCompanion: false,
      isSecondCompanion: false,
      isThirdCompanion: false,
      isFourthCompanion: false,
      isFirstApproveRequest: false,
      isSecondApproveRequest: false,
      isThirdApproveRequest: false,
      isFourthApproveRequest: false,
      approveUrl: '',

      colorItems: [
        // { codeCd: null, codeName: '요청전', colorClass: 'grey' },
        // { codeCd: 'EQS0000001', codeName: '요청중', colorClass: 'orange' },
        { codeCd: 'EQS0000010', codeName: '반려', colorClass: 'red' },
        { codeCd: 'EQS0000005', codeName: '승인완료', colorClass: 'blue' },
      ],

      assessData: {
        eiaAssessmentId: '',
        plantCd: '',
        eiaTitle: '',
        eiaDeptCd: '',
        eiaUserId: '',
        eiaDate: '',
        fstQuarterContent: '',
        fstStatusCd: '',
        secQuarterContent: '',
        secStatusCd: '',
        trdQuarterContent: '',
        trdStatusCd: '',
        frtQuarterContent: '',
        frtStatusCd: '',
        fstCompanionContent: '',
        secCompanionContent: '',
        trdCompanionContent: '',
        frtCompanionContent: '',
        regUserId: '',
        chgUserId: '',
      },
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.eiaAssessmentId)
    },
    isRegDept() { 
      return this.assessData.eiaDeptCd === this.$store.getters.user.deptCd
    },
    isEnvSafDept() { 
      return this.$_.indexOf(this.$store.getters.auths, 'SAGI000025') > -1 || this.$store.getters.user.empNo === 'admin'
    },
    resultQuarter() {
      return [
        // 1분기
        { 
          attachInfo: this.fstAttachInfo,
          param: {
            eiaAssessmentId: this.popupParam.eiaAssessmentId,
            fstStatusCd: this.assessData.fstStatusCd,
            fstCompanionContent: this.assessData.fstCompanionContent
          },
          isApprReq: this.isOld && (Boolean(!this.assessData.fstStatusCd) || this.assessData.fstStatusCd === 'EQS0000010'),
          isApprCom: this.isOld && this.assessData.fstStatusCd === 'EQS0000001',
          isApproveRequest: this.isFirstApproveRequest,
          isApprove: this.isFirstApprove,
          isCompanion: this.isFirstCompanion,
          companionContent: this.assessData.fstCompanionContent,
          statusCd: this.assessData.fstStatusCd,
          name: '1분기',
          code: 'first'
        },
        // 2분기
        { 
          attachInfo: this.secAttachInfo,
          param: {
            eiaAssessmentId: this.popupParam.eiaAssessmentId,
            secStatusCd: this.assessData.secStatusCd,
            secCompanionContent: this.assessData.secCompanionContent
          },
          isApprReq: this.isOld && (Boolean(!this.assessData.secStatusCd) || this.assessData.secStatusCd === 'EQS0000010'),
          isApprCom: this.isOld && this.assessData.secStatusCd === 'EQS0000001',
          isApproveRequest: this.isSecondApproveRequest,
          isApprove: this.isSecondApprove,
          isCompanion: this.isSecondCompanion,
          companionContent: this.assessData.secCompanionContent,
          statusCd: this.assessData.secStatusCd,
          name: '2분기',
          code: 'second'
        },
        // 3분기
        { 
          attachInfo: this.trdAttachInfo,
          param: {
            eiaAssessmentId: this.popupParam.eiaAssessmentId,
            trdStatusCd: this.assessData.trdStatusCd,
            trdCompanionContent: this.assessData.trdCompanionContent
          },
          isApprReq: this.isOld && (Boolean(!this.assessData.trdStatusCd) || this.assessData.trdStatusCd === 'EQS0000010'),
          isApprCom: this.isOld && this.assessData.trdStatusCd === 'EQS0000001',
          isApproveRequest: this.isThirdApproveRequest,
          isApprove: this.isThirdApprove,
          isCompanion: this.isThirdCompanion,
          companionContent: this.assessData.trdCompanionContent,
          statusCd: this.assessData.trdStatusCd,
          name: '3분기',
          code: 'third'
        },
        // 4분기
        { 
          attachInfo: this.frtAttachInfo,
          param: {
            eiaAssessmentId: this.popupParam.eiaAssessmentId,
            saveStatusCd: '',
            frtStatusCd: this.assessData.frtStatusCd,
            frtCompanionContent: this.assessData.frtCompanionContent
          },
          isApprReq: this.isOld && (Boolean(!this.assessData.frtStatusCd) || this.assessData.frtStatusCd === 'EQS0000010'),
          isApprCom: this.isOld && this.assessData.frtStatusCd === 'EQS0000001',
          isApproveRequest: this.isFourthApproveRequest,
          isApprove: this.isFourthApprove,
          isCompanion: this.isFourthCompanion,
          companionContent: this.assessData.frtCompanionContent,
          statusCd: this.assessData.frtStatusCd,
          name: '4분기',
          code: 'fourth'
        },
      ]
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.eia.assessment.get.url;
      this.saveUrl = transactionConfig.sai.eia.assessment.insert.url;
      this.deleteUrl = transactionConfig.sai.eia.assessment.delete.url;
      this.approveUrl = transactionConfig.sai.eia.assessment.approve.url;
      this.companionUrl = transactionConfig.sai.eia.assessment.companion.url;
      // code setting
      // list setting 
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.eiaAssessmentId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.eiaAssessmentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.assessData = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)

          this.$set(this.fstAttachInfo, 'taskKey', this.popupParam.eiaAssessmentId)
          this.$set(this.secAttachInfo, 'taskKey', this.popupParam.eiaAssessmentId)
          this.$set(this.trdAttachInfo, 'taskKey', this.popupParam.eiaAssessmentId)
          this.$set(this.frtAttachInfo, 'taskKey', this.popupParam.eiaAssessmentId)

        },);
      }
    },
    saveData() {
      if (this.popupParam.eiaAssessmentId) {
        this.saveUrl = transactionConfig.sai.eia.assessment.insert.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.eia.assessment.update.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.assessData.regUserId = this.$store.getters.user.userId;
              this.assessData.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    approveReqData(quarter) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: `${quarter.name}의 승인을 요청하시겠습니까?`, // ${quarterMsg}의 ${approveMsg}하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.assessData.regUserId = this.$store.getters.user.userId;
          this.assessData.chgUserId = this.$store.getters.user.userId;

          if (quarter.code === 'first') {
            this.$set(this.assessData, 'fstStatusCd', 'EQS0000001');
            this.isFirstApproveRequest = !this.isFirstApproveRequest
          } else if (quarter.code === 'second') {
            this.$set(this.assessData, 'secStatusCd', 'EQS0000001');
            this.isSecondApproveRequest = !this.isSecondApproveRequest
          } else if (quarter.code === 'third') {
            this.$set(this.assessData, 'trdStatusCd', 'EQS0000001');
            this.isThirdApproveRequest = !this.isThirdApproveRequest
          } else if (quarter.code === 'fourth') {
            this.$set(this.assessData, 'frtStatusCd', 'EQS0000001');
            this.isFourthApproveRequest = !this.isFourthApproveRequest
          }
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approveData(quarter) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: `${quarter.name}의 승인하시겠습니까?`, // ${quarterMsg}의 ${approveMsg}하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.assessData.regUserId = this.$store.getters.user.userId;
          this.assessData.chgUserId = this.$store.getters.user.userId;

          if (quarter.code === 'first') {
            this.$set(this.assessData, 'fstStatusCd', 'EQS0000005');
            this.isFirstApprove = !this.isFirstApprove
          } else if (quarter.code === 'second') {
            this.$set(this.assessData, 'secStatusCd', 'EQS0000005');
            this.isSecondApprove = !this.isSecondApprove
          } else if (quarter.code === 'third') {
            this.$set(this.assessData, 'trdStatusCd', 'EQS0000005');
            this.isThirdApprove = !this.isThirdApprove
          } else if (quarter.code === 'fourth') {
            this.$set(this.assessData, 'frtStatusCd', 'EQS0000005');
            this.isFourthApprove = !this.isFourthApprove
          }
          quarter.isApprove = !quarter.isApprove;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    companionData(quarter) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: `${quarter.name}의 반려하시겠습니까?`, // ${quarterMsg}의 ${approveMsg}하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.assessData.regUserId = this.$store.getters.user.userId;
          this.assessData.chgUserId = this.$store.getters.user.userId;


          if (quarter.code === 'first') {
            this.$set(this.assessData, 'fstStatusCd', 'EQS0000010');
            this.$set(this.assessData, 'fstCompanionContent', quarter.companionContent);
            this.isFirstCompanion = !this.isFirstCompanion
          } else if (quarter.code === 'second') {
            this.$set(this.assessData, 'secStatusCd', 'EQS0000010');
            this.$set(this.assessData, 'secCompanionContent', quarter.companionContent);
            this.isSecondCompanion = !this.isSecondCompanion
          } else if (quarter.code === 'third') {
            this.$set(this.assessData, 'trdStatusCd', 'EQS0000010');
            this.$set(this.assessData, 'trdCompanionContent', quarter.companionContent);
            this.isThirdCompanion = !this.isThirdCompanion
          } else if (quarter.code === 'fourth') {
            this.$set(this.assessData, 'frtStatusCd', 'EQS0000010');
            this.$set(this.assessData, 'frtCompanionContent', quarter.companionContent);
            this.isFourthCompanion = !this.isFourthCompanion
          }
          quarter.isCompanion = !quarter.isCompanion;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  //확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.eiaAssessmentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.eiaAssessmentId = result.data
      if (this.mappingType == 'POST') {
        this.$set(this.fstAttachInfo, 'isSubmit', uid())
        this.$set(this.secAttachInfo, 'isSubmit', uid())
        this.$set(this.trdAttachInfo, 'isSubmit', uid())
        this.$set(this.frtAttachInfo, 'isSubmit', uid())
      }
      this.$set(this.fstAttachInfo, 'taskKey', this.popupParam.eiaAssessmentId)
      this.$set(this.secAttachInfo, 'taskKey', this.popupParam.eiaAssessmentId)
      this.$set(this.trdAttachInfo, 'taskKey', this.popupParam.eiaAssessmentId)
      this.$set(this.frtAttachInfo, 'taskKey', this.popupParam.eiaAssessmentId)
      this.getDetail();
    },
    approveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    setTagColor(quarter) {
      let statusName = '';
      if (quarter.code === 'first') {
        statusName = 'fstStatusCd';
      } else if (quarter.code === 'second') {
        statusName = 'secStatusCd';
      } else if (quarter.code === 'third') {
        statusName = 'trdtatusCd';
      } else if (quarter.code === 'fourth') {
        statusName = 'frtStatusCd';
      }
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { codeCd: this.assessData[statusName] })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(quarter) {
      let statusName = '';
      if (quarter.code === 'first') {
        statusName = 'fstStatusCd';
      } else if (quarter.code === 'second') {
        statusName = 'secStatusCd';
      } else if (quarter.code === 'third') {
        statusName = 'trdtatusCd';
      } else if (quarter.code === 'fourth') {
        statusName = 'frtStatusCd';
      }
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { codeCd: this.assessData[statusName] })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
  }
};
</script>
<style lang="sass">
.quarterBorder 
  padding-top: 10px
  border: 1px solid
  border-color: #bbb
</style>